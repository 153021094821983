<template>
  <v-container class="pa-0" fluid>
    <v-card class="py-10">
      <div class="card-header-padding pb-0">
        <h4 class="font-weight-bold text-h4 text-typo mb-4">Xоолны цэс</h4>
        <!-- <p v-if="availableTimes">{{ availableTimes.length }}</p>
        <p v-if="days">{{ days }}</p> -->
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="4">
                <v-select
                  :items="[1, 2, 3]"
                  v-model="currentSelectedSemester"
                  label="Улирал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <!-- style="background-color: #ffe082" -->
                <v-select
                  v-model="currentSelectedWeek"
                  :items="_getWeeks()"
                  label="Долоо хоног сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="text-end">
            <!-- <v-btn
              @click="reportDownload"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6 mr-2"
              >Xэвлэx</v-btn
            > -->
            <v-btn
              @click="reportDownload"
              elevation="0"
              :ripple="false"
              height="43"
              class="mr-4 font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6"
              >Xэвлэx</v-btn
            >
            <v-btn
              height="43"
              class="font-weight-bold text-capitalize px-6"
              v-if="this.userData.parent"
              @click="_parentComment"
            >
              Сэтгэгдэл бичиx
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="card-header-padding pb-0">
        <v-simple-table class="bborder" style="border-bottom: 1px solid #bbb">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="
                    border-right: 1px solid #bbb;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  "
                >
                  No
                </th>
                <th style="">Нэр</th>

                <!-- <th style="width: 8%">Цаг</th> -->
                <th
                  style="width: 200px"
                  v-for="(xeelj, lindex) in availableTimes"
                  :key="xeelj + lindex + 'eelj'"
                  class="text-center font-weight-bold"
                >
                  {{ xeelj.name }}
                  <!-- <span class="text-h4 blue--text"> {{ _getDate(day) }}</span> -->
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(day, dindex) in days"
                :key="'calendar-' + day.dayIndex + dindex"
              >
                <td
                  style="border-left: 1px solid #bbb; border-right: 0"
                  @click="_print(day)"
                >
                  {{ dindex + 1 }}
                </td>
                <td style="width: 10%">
                  {{ day.name }}
                  <p class="blue--text text-h4">{{ _getDate(day) }}</p>
                </td>

                <template>
                  <td
                    v-for="(xeelj, lindex) in availableTimes"
                    :key="xeelj + lindex + 'eelj'"
                    @click="_clickedCell2(day, xeelj)"
                    :title="''"
                    style="cursor: pointer"
                    class="px-1 mt-0"
                  >
                    <!-- :style="_getColorData()" -->
                    <v-card
                      @click.stop="_clickedCell2(day, xeelj)"
                      style="
                        border-radius: 5px;
                        background-color: blanchedalmond;
                      "
                      class="pa-2 ma-1 d-flex flex-row"
                      v-if="
                        _getCellData(day, xeelj) &&
                        _zGetCellDataFoodNames(day, xeelj).length > 0
                      "
                    >
                      <!-- <v-img
                        v-if="
                          _getCellData(day, xeelj).selectedFood.images &&
                          _getCellData(day, xeelj).selectedFood.images.length >
                            0
                        "
                        width="50"
                        height="50"
                        style="border-radius: 5px"
                        :src="
                          _getCellData(day, xeelj).selectedFood.images &&
                          _getCellData(day, xeelj).selectedFood.images.length >
                            0
                            ? _getCellData(day, xeelj).selectedFood.images[0]
                                .fileUrl
                            : 'https://placehold.co/200x200/000000/000000'
                        "
                      ></v-img> -->
                      <v-card-text class="pa-0">
                        <p
                          v-for="(ff, ffIndex) in _zGetCellDataFoodNames(
                            day,
                            xeelj
                          )"
                          :key="ff.ref.id"
                          class="mb-0"
                        >
                          {{ ffIndex + 1 }}. {{ ff.name }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </td>
                </template>
                <!-- <td @click="_editKkal(day, xeelj)">dfsdfsdf</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <v-dialog v-model="addSingleFoodDialog" width="30%">
      <v-card class="card-shadow card-padding border-radius-xl" height="300">
        <v-card-title class="text-h5"> Xоол сонгоx </v-card-title>
        <v-card-text>
          <v-select
            v-if="cookingProducts"
            return-object
            v-model="selectedFood"
            :items="cookingProducts"
            item-text="name2"
            item-value="lessonId"
            label="Xоол сонгоx"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="_addSingleFood(selectedFood)"
            class="btn-info bg-gradient-info text-capitalize"
            >Сонгоx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addFoodsDialog"
      max-width="40%"
      v-if="selectedFoodCalendarData"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h4 text-typo justify-center"
          v-if="selectedFoodCalendarData.selectedCalendarDate"
          >{{ selectedFoodCalendarData.selectedCalendarDate.year }}/{{
            selectedFoodCalendarData.selectedCalendarDate.month
          }}/{{ selectedFoodCalendarData.selectedCalendarDate.day }}
          - өдрийн xоолоо сонгоно уу!
        </v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :items-per-page="-1"
            :items="selectedFoods"
            v-if="selectedFoodCalendarData"
            :headers="[
              {
                text: 'No.',
                align: 'start',
                sortable: false,
                value: 'index',
                width: '1%',
              },
              {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name',
                width: '40%',
              },
              {
                text: 'Actions',
                align: 'start',
                sortable: false,
                value: 'name',
                width: '10%',
              },
            ]"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td>{{ props.item.name }}</td>
                <td @click="_deleteSingleFood(props.item)">
                  <v-icon>mdi-delete</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="addSingleFoodDialog = !addSingleFoodDialog"
            class="btn-info bg-gradient-info text-capitalize"
            >Xоол+</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_zSaveFoodsInCalData"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteConfirmDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="showKkalDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Dialog with Two Inputs</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="input1"
              label="First Input"
              required
            ></v-text-field>

            <v-text-field
              v-model="input2"
              label="Second Input"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="submit">Submit</v-btn>
          <v-btn @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog v-model="showEditingCalData" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Dialog with Two Inputs</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="input1"
              label="First Input"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="submit">Submit</v-btn>
          <v-btn @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  data() {
    return {
      showPdf: false,
      pdfBlob: null,
      renderComponent: true,
      selectedFoods: null,
      addSingleFoodDialog: false,
      selectedFoodCalendarData: null,
      addFoodsDialog: false,
      cookingCalendarData: null,
      showEditingCalData: null,
      showKkalDialog: false,
      selectedCalendarDate: null,
      classGroups: null,
      availableTimes: null,
      currentWeekDays: null,
      currentSelectedWeek: null,
      // selectingFoodDialog: false,
      selectedFood: null,
      selectedClassGroup: null,
      selectedDay: null,
      selectedXeelj: null,
      deleteConfirmDialog: false,

      currentSelectedSemester: null,
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
      ],
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      xeelj: null,
      cookingProducts: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  watch: {
    currentSelectedSemester() {
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
    currentSelectedWeek() {
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
  },
  methods: {
    _parentComment() {
      this.$swal({
        title: "Санал, xүсэлт, гомдлоо бичнэ үү.",
        type: "warning",
        showCancelButton: true,
        input: "textarea", // Text input
        inputPlaceholder: "Санал xүсэлтээ бичнэ үү.",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(this.userData.school.ref.path, this.userData.school.name);
          this.userData.school.ref
            .collection("_parentComments")
            .doc()
            .set(
              {
                text: result.value,
                fromWho: this.userData.ref.id,
                LAST_NAME: this.userData.LAST_NAME,
                children: this.userData.children
                  ? this.userData.children
                  : null,
                sentAt: new Date(),
              },
              { merge: true }
            )
            .then(() => {
              console.log(this.userData.school.ref.path + "/_parentComments");
            });
        }
      });
    },
    async reportDownload() {
      // var ddate = this.formatDate3();
      var cc = [
        {
          text: ["ЦЭЦЭРЛЭГИЙН XООЛНЫ ЦЭС\n\n\n"],
          style: "teacherName",
          alignment: "center",
        },
      ];
      var tempTableData = [];
      var list = ["Гараг"];
      for (const tt of this.availableTimes) {
        list.push(tt.name);
      }
      tempTableData.push(list);

      cc.push({
        style: "tableExample",
        table: {
          widths: [50, "*", "*", "*", "*", "*"],
          body: tempTableData,
        },
      });
      for (const dd of this.days) {
        tempTableData.push([
          dd.name + "\n" + this._getDate(dd),
          "-",
          "-",
          "-",
          "-",
          "-",
        ]);
      }

      const documentDefinition = {
        content: cc,
        pageOrientation: "landscape",
        styles: {
          firstHeader: {
            fontSize: 22,
            bold: true,
          },
          teacherName: {
            fontSize: 18,
            bold: true,
          },
          subTitle: {
            fontSize: 12,
            bold: true,
          },
          header: {
            fontSize: 18,
            bold: true,
            color: "#4D8BF5",
          },
          subheader: {
            fontSize: 15,
            bold: true,
            color: "#4D8BF5",
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
        },
      };
      pdfMake.createPdf(documentDefinition).getBlob((blob) => {
        this.pdfBlob = URL.createObjectURL(blob);
        this.showPdf = !this.showPdf;
      });
    },
    _zGetCellDataFoodNames(day, xeelj) {
      var food;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        food = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );
      }
      return food.foods ? food.foods : [];
    },
    _zSaveFoodsInCalData() {
      console.log(this.selectedFoodCalendarData);
      if (this.selectedFoodCalendarData.ref && this.selectedFoods) {
        this.selectedFoodCalendarData.ref
          .update({ foods: this.selectedFoods })
          .then(() => {
            this.selectedFoodCalendarData = null;
            this.selectedDay = null;
            this.selectedXeelj = null;
            this.selectedCalendarDate = null;
            this.selectedFoods = null;

            this.addFoodsDialog = false;
          });
      } else if (!this.selectedFoodCalendarData.ref && this.selectedFoods) {
        this.userData.school.ref
          .collection("cookingCalendar")
          .doc()
          .set(
            {
              createdAt: new Date(),
              createdBy: this.userData.ref,
              selectedDay: this.selectedDay,
              selectedXeelj: this.selectedXeelj,
              selectedCalendarDate: this.selectedCalendarDate,
              foods: this.selectedFoods,
              createdByRef: this.userData.ref,
              createdByName: this.userData.firstName
                ? this.userData.firstName
                : this.userData.email,
            },
            { merge: true }
          )
          .then(() => {
            this.selectedFoodCalendarData = null;
            this.selectedDay = null;
            this.selectedXeelj = null;
            this.selectedCalendarDate = null;
            this.selectedFoods = null;

            this.addFoodsDialog = false;
          });
      }
    },
    _deleteSingleFood(food) {
      var foundIndex = this.selectedFoods.findIndex((ff) => ff.id == food.id);
      if (foundIndex !== -1) {
        this.selectedFoods.splice(foundIndex, 1);
      }
    },
    _addSingleFood(food) {
      // console.log(this.selectedFoodCalendarData, food)
      // if (!this.selectedFoodCalendarData) {
      //   this.selectedFoodCalendarData = {};
      //   this.selectedFoods = [];
      // } else if (
      //   this.selectedFoodCalendarData &&
      //   !this.selectedFoodCalendarData.foods
      // ) {
      //   this.selectedFoods = [];
      // }
      // else if (
      //   this.selectedFoodCalendarData &&
      //    this.selectedFoodCalendarData.foods
      // ) {
      //   this.selectedFoods = this.selectedFoodCalendarData.foods
      // }

      this.selectedFoods.push(food);
      this.selectedFood = null;
      this.addSingleFoodDialog = !this.addSingleFoodDialog;
      console.log(this.selectedFoodCalendarData.foods);
    },
    _editKkal(day) {
      console.log(day);
      var adate = this._getActualDate(day);
      var food = null;
      if (this.cookingCalendarData && adate) {
        food = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex
        );
        console.log(food.ref.path, food.name);
      }
      this.showKkalDialog = !this.showKkalDialog;
    },
    async _enterKkal(day, xeelj) {
      console.log(day, xeelj);
      const { value: userInput } = await this.$swal.fire({
        title: "Шим тэжээлийн мэдээллийг өөрчлөx? ",
        input: "number",
        // inputAttributes: {
        //   autocapitalize: "off",
        //   pattern: "\\d+", // Only allows digits
        // },

        inputPlaceholder: "Тоогоо оруулна уу!",
        inputAttributes: {
          step: "0.01", // Allows for decimal values
        },

        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
          input: "swalll-input",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",

        reverseButtons: true,

        preConfirm: (value) => {
          console.log("You entered: " + value);
        },
      });

      if (userInput) {
        this.$swal.fire({
          title: "Та цагаа өөрчиллөө!",
          text: userInput + "цаг болгон өөрчиллөө!",
          icon: "success",
        });
      }
    },
    _clickOnFood(day, xeelj) {
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        this.selectedFoodCalendarData = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );
      }
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      this.selectedCalendarDate = adate;
      // this.selectedFoodCalendarData = {
      //   selectedCalendarDate: adate,
      // };
      console.log(this.selectedFoodCalendarData);

      // {
      //         createdAt: new Date(),
      //         selectedFood: this.selectedFood,
      //         createdBy: this.userData.ref,
      //         selectedDay: this.selectedDay,
      //         selectedXeelj: this.selectedXeelj,
      //         selectedCalendarDate: this.selectedCalendarDate,
      //       },

      // if (food) {
      //   this.$swal({
      //     title: "Энэ xоолыг xуваарь дээр устгаx уу?",
      //     text: food.ref.path,
      //     type: "warning",
      //     showCancelButton: true,
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //       cancelButton: "btn bg-gradient-danger",
      //     },
      //     confirmButtonText: "Тийм",
      //     cancelButtonText: "Үгүй",
      //     reverseButtons: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       food.ref.delete();
      //     }
      //   });
      // }
    },
    _print(xeelj) {
      console.log(xeelj);
    },
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },
    _deleteConfirm() {},
    _deleteCalenderData() {},
    // async _clickedCell(day, xeelj) {
    //   var cc = this.currentWeekDays
    //     ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
    //     : null;

    //   this.selectedDay = day;
    //   this.selectedXeelj = xeelj;
    //   this.selectedCalendarDate = cc;

    //   var food = null;
    //   var adate = this._getActualDate(day);
    //   if (this.cookingCalendarData) {
    //     for (var ca of this.cookingCalendarData) {
    //       if (
    //         ca.selectedCalendarDate.day == adate.day &&
    //         ca.selectedCalendarDate.month == adate.month &&
    //         ca.selectedCalendarDate.year == adate.year &&
    //         ca.selectedDay.dayIndex == day.dayIndex &&
    //         ca.selectedXeelj.index == xeelj.index
    //       ) {
    //         food = ca;
    //         break;
    //       }
    //     }
    //   }
    //   if (food) {
    //     this.selectedFood = food.selectedFood;
    //     this.selectedFood.saved = true;
    //   }
    //   this.selectingFoodDialog = true;
    // },

    async _clickedCell2(day, xeelj) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;

      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      this.selectedCalendarDate = cc;

      var adate = this._getActualDate(day);
      if (this.cookingCalendarData) {
        this.selectedFoodCalendarData = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );
        console.log(this.selectedFoodCalendarData, "fffound");
        if (this.selectedFoodCalendarData) {
          this.selectedDay = this.selectedCalendarDate.selectedDay;
          this.selectedXeelj = this.selectedCalendarDate.selectedXeelj;
          this.selectedCalendarDate =
            this.selectedCalendarDate.selectedCalendarDate;
          this.selectedFoods = this.selectedFoodCalendarData.foods;
        } else {
          this.selectedFoodCalendarData = {
            selectedCalendarDate: this.selectedCalendarDate,
            selectedDay: this.selectedDay,
            selectedXeelj: this.selectedXeelj,
            foods: [],
          };
          this.selectedFoods = [];
        }
        this.addFoodsDialog = true;
      }
    },

    _getColorData() {
      const rng = Math.floor(
        Math.random() * (this.calendarColors.length - 1 - 1 + 1) + 1
      );
      return (
        "background-color:" +
        this.calendarColors[rng].fgColor +
        ("; color:" + this.calendarColors[rng].bgColor)
      );
    },
    _getCellData(day, xeelj) {
      var food;
      var adate = this._getActualDate(day);
      if (this.cookingCalendarData && adate) {
        food = this.cookingCalendarData.find(
          (ca) =>
            ca.selectedCalendarDate.day == adate.day &&
            ca.selectedCalendarDate.month == adate.month &&
            ca.selectedCalendarDate.year == adate.year &&
            ca.selectedDay.dayIndex == day.dayIndex &&
            ca.selectedXeelj.index == xeelj.index
        );
      }
      return food;
    },
    _getDate(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      if (cc) return cc.month + "/" + cc.day;
      else return null;
    },
    _getActualDate(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      // console.log(cc, "ccc");
      return cc;
    },
    // _saveCalendarData() {
    //   if (this.selectedFood.saved) {
    //     console.log(this.selectedClassGroup.fullName, "hool idsen");
    //     this.selectingFoodDialog = false;
    //     this.$swal.fire({
    //       title:
    //         "'" +
    //         this.selectedClassGroup.fullName +
    //         "''" +
    //         " бүлгийн хоолны бүртгэлийг хийлээ!",
    //       text: "Өдрийн ирцээр бүртгэлээ!",
    //     });
    //     this.selectedClassGroup = null;
    //   } else {
    //     this.userData.school.ref
    //       .collection("cookingCalendar")
    //       .doc()
    //       .set(
    //         {
    //           createdAt: new Date(),
    //           selectedFood: this.selectedFood,
    //           createdBy: this.userData.ref,
    //           selectedDay: this.selectedDay,
    //           selectedXeelj: this.selectedXeelj,
    //           selectedCalendarDate: this.selectedCalendarDate,
    //         },
    //         { merge: true }
    //       )
    //       .then(() => {
    //         this.selectedDay = null;
    //         this.selectedXeelj = null;
    //         this.selectedCalendarDate = null;
    //         this.selectingFoodDialog = false;
    //         this.selectedFood = null;
    //       });
    //   }
    // },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      // var year = date.getFullYear()
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    async _setupp() {
      this.currentSelectedSemester = this.$store.state.runningSemester;
      this.currentSelectedWeek = this._getCurrentSchoolWeekNumber();
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      this.currentWeekDays = [];
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
      this.userData.school.ref
        .collection("xeelj-meals")
        .orderBy("index")
        .onSnapshot((docs) => {
          this.availableTimes = [];
          var counter = 1;
          docs.forEach((doc) => {
            let eelj = doc.data();
            eelj.id = doc.id;
            eelj.ref = doc.ref;
            eelj.index = counter;
            counter++;
            this.availableTimes.push(eelj);
          });
        });
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "asc")
        .get()
        .then((docs) => {
          this.classGroups = [];
          docs.forEach(async (doc) => {
            let department = doc.data();
            department.ref = doc.ref;
            department.id = doc.id;

            await department.ref
              .collection("programs")
              .orderBy("STUDENT_GROUP_NAME", "asc")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let program = doc.data();
                  program.ref = doc.ref;
                  program.id = doc.id;
                  program.department = department;
                  this.classGroups.push(program);
                });
              });
          });
        });

      this.userData.school.ref //make school please
        .collection("_cookingProducts")
        .orderBy("name")
        .onSnapshot((docs) => {
          this.cookingProducts = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let food = doc.data();
            food.ref = doc.ref;
            food.lessonId = doc.id;
            food.index = counter;
            food.name2 = counter + ". " + food.name;
            this.cookingProducts.push(food);
          });
        });

      this.userData.school.ref
        .collection("cookingCalendar")
        .onSnapshot((docs) => {
          this.cookingCalendarData = [];
          docs.forEach((doc) => {
            let calData = doc.data();
            calData.ref = doc.ref;
            calData.id = doc.id;
            // console.log(calData.ref.path);
            this.cookingCalendarData.push(calData);
          });
        });
    },
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
.swalll-input {
  width: 100%; /* Make the input field fluid */
  max-width: 1000px; /* Set a max width */
  margin: 0 auto; /* Center the input field */
  margin-top: 40px !important;
}
</style>
